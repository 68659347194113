import moment from 'moment';

const helpers = {
  /**
   * Getting last week range monday-sunday
   */
  getLastWeekRange() {
    const monday = moment().startOf('isoWeek').format('YYYY-MM-DD');
    const isoMonday = moment(monday).utcOffset(0, true).toISOString();

    const sunday = moment().endOf('isoWeek').format('YYYY-MM-DD');
    const isoSunday = moment(sunday).utcOffset(0, true).toISOString();

    return {
      weekFirstDay: isoMonday,
      weekLastDay: isoSunday
    };
  },
  getCssVariable(variable) {
    // eslint-disable-next-line quotes
    const light = "[data-theme='light']";
    // eslint-disable-next-line quotes
    const dark = "[data-theme='dark']";

    const el = document.querySelector(light) || document.querySelector(dark);
    const style = getComputedStyle(el);

    return style.getPropertyValue(variable);
  },

  /**
   * Sort items from the smallest to the largest, by the key passed in the second argument
   * @param {Object} - object containing two properties
   * 1. arr - an array of objects
   * 2. key -  the key by which the array should be sorted (the values must be numbers)
   * @returns {Arr} - returns an array of objects sorted based on "key" value
   */
  sortArrayItemsByKey(params) {
    const { arr, key, reverse = false } = params;
    return arr.sort((a, b) => {
      if (reverse) {
        return b[key] - a[key];
      }
      return a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0;
    });
  }
};

export default helpers;

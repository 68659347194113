import store from '@/store/index';

export default [
  {
    path: '/notifications-settings',
    name: 'notificationsSettings',
    beforeEnter: (to, from, next) => {
      // Only the admin can go to notifications page
      const isAdmin = store.getters['user/isAdmin'];

      if (isAdmin) {
        next();
        return;
      }

      next({
        name: 'home'
      });
    },
    component: () => import(/* webpackChunkName: "home" */ '@views/admin/Notifications.vue')
  }
];

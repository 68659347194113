const ADMIN = 'admin';
const CLIENT = 'client';
const EDITOR = 'editor';
const PHOTOGRAPHER = 'photographer';

const defaultState = () => {
  return {
    user: {},
    wizardUser: {
      password: '',
      additionalInformations: {
        company_name: '',
        industry: '',
        size: 0,
        country: '',
        contact_name: '',
        contact_email: '',
        contact_phone: '',
        contact_surname: '',
        birthdate: '',
        english_level: '',
        main_location: '',
        arabic_level: '',
        details: {
          bio: '',
          portfolio_link: ''
        }
      },
      bank: {
        accountName: '',
        accountNumber: '',
        bankCode: '',
        bankName: '',
        branch: '',
        country: '',
        iban: '',
        swift: ''
      },
      user: {
        name: '',
        email: '',
        phone: '',
        surname: ''
      },
      paymentDetail: {
        type: 'test',
        details: {}
      }
    },
    registrationCompleted: false
  };
};

const user = {
  namespaced: true,
  state: defaultState(),
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState());
    },
    saveUser(state, user) {
      state.user = user;
    },
    updateRegistrationCompleted(state, isCompleted) {
      state.registrationCompleted = isCompleted;
    },
    updateWizardUser(state, user) {
      state.wizardUser = user;
    }
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    saveUser({ commit }, user) {
      commit('saveUser', user);
    },
    setRegistrationCompleted({ commit }, isCompleted) {
      commit('updateRegistrationCompleted', isCompleted);
    },
    updateWizardUser({ commit, state }, user) {
      const currentValue = state.wizardUser;
      const updatedValue = {
        ...currentValue,
        ...user
      };

      commit('updateWizardUser', updatedValue);
    }
  },
  getters: {
    getUser: state => state.user,
    getRegistrationCompleted: state => state.registrationCompleted,
    getWizardUser: state => state.wizardUser,
    isAdmin: state => ADMIN === state.user.role,
    isClient: state => CLIENT === state.user.role,
    isEditor: state => EDITOR === state.user.role,
    isPhotographer: state => PHOTOGRAPHER === state.user.role,
    isPhotographerInternal: state => PHOTOGRAPHER === state.user.role && state.user.is_internal,
    isUserLoggedIn: state => Object.keys(state.user).length > 0,
    isSubClient: state => CLIENT === state.user.role && state.user.parent_client_id,
    isB2C: state => state.user.company_name && state.user.company_name.endsWith('B2C')
  }
};

export default user;

import axios from 'axios';
import store from '../store/index';

// Utils
import { getCookie } from '@utils/cookies';

const instance = axios.create({
  baseURL: `${process.env.VUE_APP_BASE_DOMAIN}/v1`
});

// Setting bearer from cookie before every api call
instance.interceptors.request.use(config => {
  const jwt = getCookie('jwt');
  config.headers.Authorization = `Bearer ${jwt}`;

  return config;
});

// Intercepting very response and formatting it for FE
instance.interceptors.response.use(
  response => {
    const formattedResponse = {
      ...response.data
    };
    return formattedResponse;
  },
  error => {
    const { data = {}, status = {} } = error.response;
    const { errors = [] } = data;
    const { url = '' } = error.toJSON().config;

    const _error = {
      endpoint: 'GENERIC'
    };

    if (url.indexOf('signup') !== -1) {
      _error.endpoint = 'SIGNUP';

      const doesAccountExists = errors.some(_error => _error.indexOf('The user already exists.') !== -1);
      if (doesAccountExists) store.dispatch('error/addError', { message: 'An account with this email already exists' });
    }

    return { statusCode: status };
  }
);

export const getUri = ({ params = '', url }) => {
  return `${process.env.VUE_APP_BASE_DOMAIN}/v1/${url}?__bearer_access_token=${getCookie('jwt')}&${params}`;
};

export default instance;

export { MediaApi } from './modules/media';
export { OrderApi } from './modules/order';
export { PackagesApi } from './modules/package';
export { ReportApi } from './modules/report';
export { ServiceApi } from './modules/services';
export { SettingsApi } from './modules/settings';
export { ShootsApi } from './modules/shoots';
export { UserApi } from './modules/user';
export { QuotesApi } from './modules/quotes';
export { ConversationsApi } from './modules/conversations';

<template>
  <div class="baseDropdown">
    <div ref="trigger">
      <slot name="trigger"> </slot>
    </div>
    <q-menu ref="menu" :anchor="anchor" :self="self" content-class="baseDropdown__menu" :offset="[0, 5]" :target="$refs.trigger" @before-hide="$emit('hide')" @before-show="$emit('show')">
      <slot name="menu" />
    </q-menu>
  </div>
</template>

<script>
export default {
  // Changed
  name: 'BaseDropdown',
  props: {
    /**
     * Use this prop to set where the dropdown should be anchored from trigger side
     */
    anchor: { default: 'bottom right', type: String },
    /**
     * Use this prop to set where the dropdown should be anchored from menu side
     */
    self: { default: 'top right', type: String }
  },
  methods: {
    /**
     * Use this method to toggle the dropdown
     *
     * @public
     */
    toggleMenu() {
      this.$refs.menu.toggle();
    }
  }
};
</script>

<style lang="scss">
.baseDropdown {
  align-items: center;
  display: flex;
  justify-content: center;

  & > div {
    width: 100%;
  }

  &__menu {
    background-color: transparent !important;
    border-radius: 10px;
    box-shadow: unset !important;
    max-height: unset;
    max-width: unset !important;
  }

  &__trigger {
    display: table;
    width: 100%;
  }
}
</style>

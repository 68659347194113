const defaultState = () => {
  return {
    services: []
  };
};

const services = {
  namespaced: true,
  state: defaultState(),
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState());
    },
    /**
     * Mutation used to update the state array of services
     */
    updateServices(state, services) {
      state.services = services;
    }
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    /**
     * Action used to pass the updated services array
     */
    updateServices({ commit }, services) {
      commit('updateServices', services);
    }
  },
  getters: {
    /**
     * Retrieving the store services
     */
    getServices: state => state.services
  }
};

export default services;

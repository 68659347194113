import instance from '../index';

const RES_NOTIFICATIONS = 'notificationsettings';

export const SettingsApi = {
  /**
   * Retrieving list of available notifications
   */
  getNotifications() {
    return instance.get(RES_NOTIFICATIONS);
  },
  settings() {
    return instance.get('settings');
  },
  /**
   * Update a given notification status (true/false)
   */
  updateNotification({ id, status }) {
    return instance.post(`${RES_NOTIFICATIONS}/${id}/update`, { status });
  }
};

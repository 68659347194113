const defaultState = () => {
  return {
    header: 'Your shoot has been created',
    primaryText:
      'Please find your shoot on the home page. You can verify the details and check its status directly from the platform.Once the shoot is completed, you can download your content by clicking on the link on the shoot card.',
    secondaryText: '',
    type: 'express'
  };
};

const alert = {
  namespaced: true,
  state: defaultState(),
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState());
    },
    /**
     * Update status errors
     */
    newAlert(state, data) {
      state.type = data.type;
      state.secondaryText = '';
      if (data.type === 'express' && data.isAdmin === true) {
        state.header = 'Your shoot has been created';
        state.primaryText = 'The customer has access to this shoot.';
        return;
      }
      if (data.type === 'custom' && data.isAdmin === true && data.redeemable > 0) {
        state.header = 'The redeemable package has been created';
        state.primaryText = 'The customer has access to this redeemable shoot.';
        return;
      }
      if (data.type === 'custom' && data.isAdmin === true && data.redeemable === -1) {
        state.header = 'The redeemable package has been created';
        state.primaryText = 'The customer has access to this redeemable shoot.';
        return;
      }
      if (data.type === 'custom' && data.isAdmin === true && data.redeemable === undefined) {
        state.header = 'The custom shoot has been created';
        state.primaryText = 'The customer has access to this custom shoot.';
        return;
      }
    }
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    /**
     * Add new error to state errors array
     */
    sendAlert({ commit }, type) {
      commit('newAlert', type);
    }
  },
  getters: {
    /**
     * Retrieving errors
     */
    getAlert: state => {
      return {
        header: state.header,
        primaryText: state.primaryText,
        secondaryText: state.secondaryText,
        type: state.type
      };
    },
    getType: state => state.type
  }
};

export default alert;

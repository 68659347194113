<template>
  <q-list class="userDropdown">
    <template v-for="(element, index) in dropDownElements">
      <q-item v-if="!element.isDisabled" :key="index" clickable class="userDropdown__box">
        <q-item-section class="userDropdown__item" @click="handleAction(element.action)">
          <q-icon :name="element.icon" style="font-size: 22px; margin-right: 12px"></q-icon>
          {{ element.name }}
        </q-item-section>

        <BaseTooltip v-if="element.isTooltipEnabled === true" :tooltip="tooltip" />
      </q-item>
    </template>
  </q-list>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex';

// Base Components
import BaseTooltip from '@base/BaseTooltip.vue';

/**
 * Component used to handle the user dropdown content
 *
 * @displayName UserDropdown
 */
export default {
  name: 'UserDropdown',
  components: {
    BaseTooltip
  },
  data() {
    return {
      tooltip: this.$t('COMING_SOON')
    };
  },
  computed: {
    ...mapGetters({
      isAdmin: 'user/isAdmin',
      isEditor: 'user/isEditor',
      isPhotographer: 'user/isPhotographer',
      isSubClient: 'user/isSubClient',
      user: 'user/getUser'
    }),
    dropDownElements() {
      return [
        {
          name: this.$t('ACCOUNT_INFORMATION'),
          action: 'ACCOUNT_INFO',
          isDisabled: this.isAdmin || this.isEditor || this.isSubClient,
          icon: 'person'
        },
        {
          name: this.$t('MANAGE_NOTIFICATION'),
          action: 'SHOW_NOTIFICATIONS_SETTINGS',
          isDisabled: !this.isAdmin,
          icon: 'settings'
        },
        {
          name: 'Generate Quote',
          action: 'GENERATE_QUOTATIONS',
          isDisabled: !this.isAdmin,
          icon: 'request_quote'
        },
        {
          name: this.$t('PAYMENT_DETAILS'),
          action: 'PAYMENT_DETAILS',
          isDisabled: !this.isPhotographer,
          icon: 'credit_card'
        },
        {
          icon: 'logout',
          name: 'Logout',
          action: 'LOGOUT'
        }
      ];
    }
  },
  methods: {
    /**
     * Method used to perform a specific action based on the link clicked from the dropdown
     */
    handleAction(action) {
      const actions = {
        ACCOUNT_INFO: () => this.$router.push({ name: 'account' }),
        LOGOUT: () => this.handleLogout(),
        PAYMENT_DETAILS: () => this.$router.push({ name: 'paymentDetails' }),
        SHOW_NOTIFICATIONS_SETTINGS: () => this.$router.push({ name: 'notificationsSettings' }),
        GENERATE_QUOTATIONS: () => this.$router.push({ name: 'quotes' })
      };

      actions[action]();
    }
  }
};
</script>

<style lang="scss">
.addBorders {
  border-radius: 10px;
}

.userDropdown {
  @include inter-font($size: 14px);
  background: #fff;
  border: 1px solid #979797;
  border-radius: 10px;

  &__box {
    align-items: flex-end;
    flex-direction: column;
  }

  .q-item {
    min-height: auto;
    margin: 8px 0px;
    padding: 0px 16px;
  }

  &__item {
    width: 100% !important;
    display: inline-block !important;
    text-align: left;
    padding: 7px 0;
  }
}
</style>

import store from '@/store/index';

export default [
  {
    path: '/ph-registration',
    name: 'phRegistration',
    component: () => import(/* webpackChunkName: "phRegistration" */ '@views/registration/PhotographerRegistration.vue')
  },
  {
    path: '/business-registration',
    name: 'businessRegistration',
    component: () => import(/* webpackChunkName: "businessRegistration" */ '@views/registration/BusinessRegistration.vue')
  },
  {
    path: '/flashy-shoots',
    name: 'flashyShoots',
    component: () => import(/* webpackChunkName: "flashyShoots" */ '@views/registration/FlashyShoots.vue')
  }
];

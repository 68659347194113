<template>
  <BaseDropdown>
    <template #trigger>
      <BaseButton icon="user" :label="user" :custom_style="style" :icon_style="icon_style" />
    </template>
    <template #menu>
      <UserDropdown />
    </template>
  </BaseDropdown>
</template>

<script>
// Base component
import BaseButton from '@base/BaseButton.vue';
import BaseDropdown from '@base/BaseDropdown.vue';

// Components
import UserDropdown from '@/components/UserDropdown.vue';

// Utils
import helpers from '@utils/helpers';

export default {
  name: 'UserButton',
  components: {
    BaseButton,
    BaseDropdown,
    UserDropdown
  },
  data() {
    return {
      icon_style: {
        fill: 'transparent',
        height: '26px',
        stroke: helpers.getCssVariable('--terziary-text-color'),
        'min-width': '24px'
      },
      style: {
        'background-color': helpers.getCssVariable('--secondary-btn-color'),
        color: helpers.getCssVariable('--terziary-text-color')
      },
      showDropdownMenu: false
    };
  },
  computed: {
    user() {
      const user = this.$store.getters['user/getUser'];
      return `${user.name}`;
    }
  }
};
</script>

const defaultState = () => {
  return {
    brief: null,
    company_name: '',
    shoot: {
      packages: [],
      photographer_id: null,
      client_id: null,
      is_payed: false,
      location: {},
      name: 'Package Name',
      notes: undefined,
      total_price: 0,
      total_revenue: 0,
      time: {
        duration: 0
      },
      type: '',
      content: ''
    },
    validPhone: null,
    validEmail: null
  };
};

const bookShoot = {
  namespaced: true,
  state: defaultState(),
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState());
    },
    /**
     * Mutation used to update the selected business customer for the new shoot
     */
    saveBrief(state, brief) {
      state.brief = brief;
    },
    /**
     * Mutation used to update the selected business customer for the new shoot
     */
    saveCompanyName(state, name) {
      state.company_name = name;
    },
    /**
     * Mutation used to save the new shoot information
     */
    saveShoot(state, shoot) {
      state.shoot = shoot;
    },
    setValidPhone(state, pocPhone) {
      const numberRegex = new RegExp(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/);

      if (pocPhone) state.validPhone = numberRegex.test(pocPhone.replace(/\s/g, ''));
      else state.validPhone = null;
    },
    setValidEmail(state, pocEmail) {
      const pattern = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);

      if (pocEmail?.length > 0) state.validEmail = pattern.test(pocEmail.toLowerCase());
      else state.validEmail = null;
    }
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    /**
     * Action used to update the customer name for the new shoot, takes in input only the new name
     */
    updateBrief({ commit }, brief) {
      commit('saveBrief', brief);
    },
    /**
     * Action used to update the customer name for the new shoot, takes in input only the new name
     */
    updateCompanyName({ commit }, name) {
      commit('saveCompanyName', name);
    },
    /**
     * Call this action to save a new shoot, it takes the new shoot in input and passes it to the mutation
     */
    updateShoot({ commit, state }, shoot) {
      const updatedShoot = {
        ...state.shoot,
        ...shoot
      };

      commit('saveShoot', updatedShoot);
    }
  },
  getters: {
    /**
     * Retrieving customer name
     */
    getBrief: state => state.brief,
    /**
     * Retrieving customer name
     */
    getCompanyName: state => state.company_name,
    /**
     * Retrieving the new shoot info
     */
    getShoot: (state, getters, rootState, rootGetters) => {
      if (rootGetters['user/isClient']) {
        const stateClone = JSON.parse(JSON.stringify(state.shoot));
        delete stateClone.client_id;
        delete stateClone.photographer_id;
        delete stateClone.is_payed;
        return stateClone;
      }

      return state.shoot;
    },
    /**
     * Get the value of current shoot reedemable.total
     */
    getShootReedemable: state => {
      return state.shoot.redeemable_total;
    },
    getValidPhone: state => state.validPhone,
    getValidEmail: state => state.validEmail
  }
};

export default bookShoot;

const defaultState = () => {
  return {
    openedPackageIndex: 0,
    packages: []
  };
};

const pkg = {
  namespaced: true,
  state: defaultState(),
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState());
    },
    /**
     * Mutation used to update the selected package
     */
    saveOpenedPackage(state, index) {
      state.openedPackageIndex = index;
    },
    /*
     * Mutation used to update the package Array
     */
    savePackages(state, data) {
      state.packages = data;
    }
  },
  actions: {
    /**
     * Action used for save package into packages array
     */
    pushPackages({ commit }, data) {
      commit('savePackages', data);
    },
    /**
     * Action used for remove selected package form packages Array
     */
    removePackages({ commit, state }, id) {
      const newPackages = state.packages.filter(pkg => pkg.id !== id);
      commit('savePackages', newPackages);
    },
    /**
     * Action used to update a selected package
     */
    updatePackage({ commit, state }, pkg) {
      const packageIndex = state.packages.findIndex(_package => _package.id === pkg.id);
      const packagesClone = JSON.parse(JSON.stringify(state.packages));
      packagesClone.splice(packageIndex, 1, pkg);

      commit('savePackages', packagesClone);
    },

    resetState({ commit }) {
      commit('resetState');
    },
    /**
     * Action used to update the selected Package
     */
    saveOpenedPackage({ commit }, index) {
      commit('saveOpenedPackage', index);
    }
  },
  getters: {
    /**
     * Retrieving selected package
     */
    getSelectedPackage: state => state.openedPackageIndex,
    /**
     * Retrieving packages from state
     */
    getPackages: state => state.packages
  }
};

export default pkg;

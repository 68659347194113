const defaultState = () => {
  return {
    notification: {}
  };
};

const notifications = {
  namespaced: true,
  state: defaultState(),
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState());
    },
    /**
     * Update status notification
     */
    updateNotification(state, notification) {
      state.notification = notification;
    }
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    /**
     * Add new notification when error occures
     */
    addFailureNotification({ commit }, notification) {
      const _notification = { color: 'negative', textColor: 'white', position: 'top-right', message: notification };
      commit('updateNotification', _notification);
    },
    /**
     * Add new notification
     */
    addSuccessNotification({ commit }, notification) {
      const _notification = { color: 'primary', textColor: 'black', position: 'top-right', message: notification };
      commit('updateNotification', _notification);
    }
  },
  getters: {
    /**
     * Retrieving errors
     */
    getNotification: state => state.notification
  }
};

export default notifications;

import store from '@/store/index';

export default [
  {
    path: '/book-shoot',
    name: 'bookShoot',
    beforeEnter: (to, from, next) => {
      const isAdmin = store.getters['user/isAdmin'];
      const isClient = store.getters['user/isClient'];

      if (isAdmin || isClient) {
        next();
        return;
      }

      next({
        name: 'home'
      });
    },
    component: () => import(/* webpackChunkName: "home" */ '@views/shoot/Book.vue')
  },
  {
    path: '/redeem-shoot',
    name: 'redeemShoot',
    beforeEnter: (to, from, next) => {
      const isSubClient = store.getters['user/isSubClient'];

      if (isSubClient) {
        next();
        return;
      }

      next({
        name: 'home'
      });
    },
    component: () => import(/* webpackChunkName: "home" */ '@views/subclient/RedeemShoot.vue')
  }
];

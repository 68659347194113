import store from '@/store/index';

export default [
  {
    path: '/report',
    name: 'report',
    beforeEnter: (to, from, next) => {
      // Only the admin can go to packages page
      const isAdmin = store.getters['user/isAdmin'];

      if (isAdmin) {
        next();
        return;
      }

      next({
        name: 'home'
      });
    },
    component: () => import(/* webpackChunkName: "home" */ '@views/admin/Report.vue')
  }
];

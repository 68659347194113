const defaultState = () => {
  return {
    totalItemsRetrieved: 0,
    totalItemsToRetrieve: 0
  };
};

const infiniteScroll = {
  namespaced: true,
  state: defaultState(),
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState());
    },
    /**
     * Update state total items retrieved
     */
    totalItemsRetrieved(state, value) {
      state.totalItemsRetrieved = Number(value);
    },
    /**
     * Update state total items to retrieve
     */
    totalItemsToRetrieved(state, value) {
      state.totalItemsToRetrieve = Number(value);
    }
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    /**
     * Action used to update the items counters
     */
    updateInfiniteScrollCounters({ commit }, counters) {
      const { retrieved, total } = counters;

      commit('totalItemsRetrieved', retrieved);
      commit('totalItemsToRetrieved', total);
    }
  },
  getters: {
    /**
     * Returns if all items has been retrieved
     */
    areAllItemsRetrieved: state => state.totalItemsRetrieved === state.totalItemsToRetrieve
  }
};

export default infiniteScroll;

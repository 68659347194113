import instance from '../index';

const RESOURCE_PACKAGE = 'package';
const RESOURCE_PACKAGES = 'packages';

export const PackagesApi = {
  create(payload) {
    delete payload.status;

    return instance.post(`${RESOURCE_PACKAGE}`, payload);
  },
  delete(id) {
    return instance.delete(`${RESOURCE_PACKAGE}/delete/${id}`);
  },
  search({ payload }) {
    return instance.post(`${RESOURCE_PACKAGES}/search`, payload);
  },
  update({ id, payload }) {
    delete payload.id;
    delete payload.creation_date;
    delete payload.currency;
    delete payload.last_update;
    delete payload.price_per_additional_photo;
    delete payload.status;
    delete payload.num_pictures;

    return instance.post(`${RESOURCE_PACKAGE}/update/${id}`, payload);
  }
};

import store from '@/store/index';

export default [
  {
    path: '/accounts',
    name: 'accounts',
    beforeEnter: (to, from, next) => {
      // Only the admin can go to packages page
      const isAdmin = store.getters['user/isAdmin'];

      if (isAdmin) {
        next();
        return;
      }

      next({
        name: 'home'
      });
    },
    component: () => import(/* webpackChunkName: "accounts" */ '@views/admin/Accounts.vue')
  },
  {
    path: '/sub-accounts',
    name: 'subAccounts',
    beforeEnter: (to, from, next) => {
      const isClient = store.getters['user/isClient'];

      if (isClient) {
        next();
        return;
      }

      next({
        name: 'home'
      });
    },
    component: () => import(/* webpackChunkName: "accounts" */ '@views/client/SubAccounts.vue')
  },
  {
    path: '/account',
    name: 'account',
    beforeEnter(to, from, next) {
      const isClient = store.getters['user/isClient'];
      const isPhotographer = store.getters['user/isPhotographer'];

      if (!isClient && !isPhotographer) {
        next({ name: 'home' });
        return;
      }

      next();
    },
    component: () => import(/* webpackChunkName: "account" */ '@views/PersonalArea.vue')
  },
  {
    path: '/payment-details',
    name: 'paymentDetails',
    beforeEnter(to, from, next) {
      const isPhotographer = store.getters['user/isPhotographer'];

      if (isPhotographer) {
        next();
        return;
      }

      next({ name: 'home' });
    },
    component: () => import(/* webpackChunkName: "paymentDetails" */ '@views/photographer/PaymentDetails.vue')
  }
];

<template>
  <div
    v-if="
      isUserLoggedIn || $route.name === 'phRegistration' || $route.name === 'flashyShoots' || $route.name === 'businessRegistration' || $route.name === 'stripeSuccess' || $route.name === 'stripeError'
    "
    id="app"
  >
    <PageLayout :sections="sections">
      <template #navbar>
        <Navbar v-if="isUserLoggedIn" />
      </template>
      <template #body>
        <div class="app__body">
          <router-view />
        </div>
      </template>
    </PageLayout>
  </div>
</template>

<script>
// Api
import { SettingsApi } from '@api/index';

// Vuex
import { mapGetters } from 'vuex';

// Components
import PageLayout from '@components/layout/PageLayout.vue';

// Common components
import Navbar from '@common/Navbar.vue';

export default {
  name: 'App',
  components: {
    Navbar,
    PageLayout
  },
  data() {
    return {
      sections: [
        {
          name: 'navbar'
        },
        {
          name: 'body'
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      error: 'error/getError',
      notification: 'notification/getNotification',
      isAdmin: 'user/isAdmin',
      isClient: 'user/isClient',
      isEditor: 'user/isEditor',
      isPhotographer: 'user/isPhotographer',
      isUserLoggedIn: 'user/isUserLoggedIn'
    })
  },
  watch: {
    error() {
      this.$q.notify({ color: 'negative', message: this.error.message || this.$t(`ENDPOINT.ERROR.${this.error.endpoint}`) });
    },
    notification() {
      this.$q.notify(this.notification);
    }
  },
  async created() {
    if (this.isUserLoggedIn) {
      const [services, settings] = await Promise.all([this.getServices(), SettingsApi.settings()]);

      this.$store.dispatch('services/updateServices', services);
      this.$store.dispatch('settings/updateSettings', settings.data);
    }
  }
};
</script>

<style lang="scss">
#app {
  background-color: var(--main-bg-color);
  font-family: $inter-regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
  min-height: 100vh;
}

.app {
  &__body {
    height: calc(100vh - #{$header-height});
  }
}

.ml-auto {
  margin-left: auto;
}

.w-100 {
  width: 100%;
}
</style>

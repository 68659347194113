const defaultState = () => {
  return {
    openedShootId: 0,
    successAlertShown: false,
    shoots: [],
    toggleType: 'grid',
    showFilters: false,
    isUploading: false // State to track if uploading is in progress
  };
};

const shoot = {
  namespaced: true,
  state: defaultState(),
  mutations: {
    // Close the success alert
    closeSuccessAlert(state) {
      state.successAlertShown = false;
    },

    // Open the success alert
    openSuccessAlert(state) {
      state.successAlertShown = true;
    },

    // Reset the state to its default values
    resetState(state) {
      Object.assign(state, defaultState());
    },

    // Update the ID of the currently selected shoot
    saveOpenedShoot(state, index) {
      state.openedShootId = index;
    },

    // Update the list of shoots
    saveShoots(state, data) {
      state.shoots = data;
    },

    // Update the display type for shoots (e.g., grid or list view)
    updateView(state, value) {
      state.toggleType = value;
    },

    // Update the visibility of filters
    updateShowFilters(state, value) {
      state.showFilters = value;
    },

    // Set the uploading state
    setIsUploading(state, value) {
      state.isUploading = value;
    }
  },
  actions: {
    // Handle the closing of the success alert
    handleCloseSuccessAlert({ commit }) {
      commit('closeSuccessAlert');
    },

    // Handle the opening of the success alert
    handleOpenSuccessAlert({ commit }) {
      commit('openSuccessAlert');
    },

    // Add a shoot to the list of shoots
    pushShoots({ commit }, data) {
      commit('saveShoots', data);
    },

    // Remove a shoot from the list of shoots
    removeShoots({ commit, state }, id) {
      const newShoots = state.shoots.filter(shoot => shoot.id !== id);
      commit('saveShoots', newShoots);
    },

    // Update a specific shoot in the list of shoots
    updateShoot({ commit, state }, shoot) {
      const shootIndex = state.shoots.findIndex(_shoot => _shoot.id === shoot.id);
      const shootsClone = JSON.parse(JSON.stringify(state.shoots));
      shootsClone.splice(shootIndex, 1, shoot);
      commit('saveShoots', shootsClone);
    },

    // Reset state to its default values
    resetState({ commit }) {
      commit('resetState');
    },

    // Update the ID of the currently selected shoot
    saveOpenedShoot({ commit }, id) {
      commit('saveOpenedShoot', id);
    },

    // Start the upload process
    startUpload({ commit }) {
      commit('setIsUploading', true);
    },

    // Finish the upload process
    finishUpload({ commit }) {
      commit('setIsUploading', false);
    }
  },
  getters: {
    // Retrieve the ID of the currently selected shoot
    getSelectedShoot: state => state.openedShootId,

    // Retrieve the list of shoots
    getShoots: state => state.shoots,

    // Check if the success alert is open
    getIsSuccessAlertOpen: state => state.successAlertShown,

    // Retrieve the current display type for shoots
    getActiveView: state => state.toggleType,

    // Check if filters are visible
    getShowFilters: state => state.showFilters,

    // Check if the upload is in progress
    getIsUploading: state => state.isUploading
  }
};

export default shoot;

// ConversationsApi.js
import instance from '../index';

const RESOURCE_CONVERSATIONS = 'conversations';

export const ConversationsApi = {
  getAllConversations() {
    return instance.get(`${RESOURCE_CONVERSATIONS}`);
  },

  getConversationHistory(conversationId) {
    return instance.get(`${RESOURCE_CONVERSATIONS}/${conversationId}`);
  },

  createConversation(metadata = {}) {
    return instance.post(`${RESOURCE_CONVERSATIONS}`, { metadata });
  },

  deleteConversation(conversationId) {
    return instance.delete(`${RESOURCE_CONVERSATIONS}/${conversationId}`);
  }
};

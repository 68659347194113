<template>
  <BaseButton icon="plus" :icon_style="iconStyle" :custom_style="buttonStyle" :label="$t('New customer')" @click.native="$router.replace({ name: 'businessRegistration' })" />
</template>

<script>
// Base Components
import BaseButton from '@base/BaseButton.vue';

// Utils
import helpers from '@utils/helpers';

export default {
  name: 'RegisterBusinessButton',
  components: {
    BaseButton
  },
  data() {
    return {
      buttonStyle: {
        'background-color': helpers.getCssVariable('--terziary-btn-color'),
        'box-shadow': `0 3px 14px ${helpers.getCssVariable('--main-btn-color-shadow')}`,
        color: helpers.getCssVariable('--main-text-color'),
        'margin-right': '10px'
      },
      iconStyle: {
        fill: helpers.getCssVariable('--main-text-color'),
        stroke: helpers.getCssVariable('--main-text-color')
      }
    };
  },
  computed: {
    redirectRoute() {
      return 'businessRegistration';
    }
  }
};
</script>

const defaultState = () => {
  return {
    isChangingStep: false,
    nextDisabled: false,
    step: 1,
    steps: []
  };
};

const stepper = {
  namespaced: true,
  state: defaultState(),
  mutations: {
    /**
     * Resetting the state to its default
     */
    resetState(state) {
      Object.assign(state, defaultState());
    },
    udateNextDisabled(state, isDisabled) {
      state.nextDisabled = isDisabled;
    },
    /**
     * Set if the stepper is trying to change step
     */
    updateIsChangingStep(state, isChanging) {
      state.isChangingStep = isChanging;
    },
    /**
     * Updating step
     */
    updateStep(state, step) {
      state.step = step;
    },
    /**
     * Updating available steps
     */
    updateSteps(state, step) {
      state.steps = step;
    }
  },
  actions: {
    /**
     * Call this action when the stepper is trying to go to the previous or next step
     */
    setIsChangingStep({ commit }, isChanging) {
      commit('updateIsChangingStep', isChanging);
    },
    setIsDisabled({ commit }, value) {
      commit('udateNextDisabled', value);
    },
    /**
     * Increasing the step
     */
    goToNextStep({ commit, state }) {
      commit('updateStep', state.step + 1);
      commit('updateIsChangingStep', false);
    },
    /**
     * Decreasing the step
     */
    goToPrevStep({ commit, state }) {
      commit('updateStep', state.step - 1);
      commit('updateIsChangingStep', false);
    },
    /**
     * Setting a specific step
     */
    setStep({ commit }, step) {
      commit('updateStep', step);
    },
    /**
     * Resetting the state to its default
     */
    resetState({ commit }) {
      commit('resetState');
    },
    /**
     * Updating available steps
     */
    updateSteps({ commit }, value) {
      commit('updateSteps', value);
    }
  },
  getters: {
    /**
     * Retrieve if the user is trying to change the step
     */
    getIsChangingStep: state => state.isChangingStep,
    isNextDisabled: state => state.nextDisabled,
    /**
     * Retrieving the current step
     */
    getStep: state => state.step,
    /**
     * Retrieving the current step
     */
    getSteps: state => state.steps
  }
};

export default stepper;

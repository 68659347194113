<template>
  <svg class="baseIcon" :fill="fill" :stroke="stroke" stroke-width="1.25" :style="custom_style" @click="$emit('click')" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- using my graphical objects -->
    <use :href="`${require('@/assets/symbol-defs.svg')}#${icon}`" />
    <BaseTooltip v-if="tooltip" :tooltip="tooltip" />
  </svg>
</template>

<script>
import BaseTooltip from '@base/BaseTooltip.vue';
/**
 * This component uses the svg sprite pattern to load icons
 *
 * @displayName BaseIcon
 */
export default {
  name: 'BaseIcon',
  components: {
    BaseTooltip
  },
  props: {
    /**
     * Icon custom style
     */
    custom_style: { default: () => ({}), type: Object },
    /**
     * This prop is used to pass the icon we want to be displayed
     */
    icon: { default: '', type: String },
    /**
     * Icon color
     */
    fill: { default: 'transparent', type: String },
    /**
     * Stroke color
     */
    stroke: { default: '#151515', type: String },
    /**
     * Tooltip
     */
    tooltip: { default: '', type: String }
  }
};
</script>

<style lang="scss" scoped>
.baseIcon {
  height: 24px;
  width: 24px;
}
</style>

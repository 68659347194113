const defaultState = () => {
  return {
    error: {}
  };
};

const error = {
  namespaced: true,
  state: defaultState(),
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState());
    },
    /**
     * Update status errors
     */
    updateErrors(state, error) {
      state.error = error;
    }
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    /**
     * Add new error to state errors array
     */
    addError({ commit }, error) {
      commit('updateErrors', error);
    }
  },
  getters: {
    /**
     * Retrieving errors
     */
    getError: state => state.error
  }
};

export default error;

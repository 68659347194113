/**
 * Check if the screenwidth is wider/narrower than the breakpoint
 * @param {Object}  - object with lowerBoundary or upperBoundary
 * @returns {Boolean} - if upperBoundary is passed - returns true if the screen width is narrower than the breakpoint
 * if lowerBoundary is passed - returns true if the screen width is wider than the breakpoint
 **/

const checkBreakpoint = {
  methods: {
    checkBreakpoint({ lowerBoundary = null, upperBoundary = null }) {
      const screenWidth = parseInt(window.innerWidth);
      if (lowerBoundary) return screenWidth > lowerBoundary;
      if (upperBoundary) return screenWidth < upperBoundary;
    }
  }
};

export default checkBreakpoint;

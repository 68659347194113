import instance from '../index';

const RES_ACTION = 'action';
const RESOURCE_SIGNUP = 'signup';
const RES_USER = 'user';
const RES_USERS = 'users';
const RES_BUSINESS = 'client';

const formatClientPayload = payload => {
  return {
    role: payload.role,
    additionalInformations: {
      company_name: payload.company_name,
      industry: payload.industry,
      contact_name: payload.contact_name,
      contact_email: payload.contact_email,
      contact_phone: payload.contact_phone,
      main_location: payload.main_location,
      size: payload.size,
      country: payload.country
    },
    status: payload.status,
    user: {
      name: payload.name,
      phone: payload.phone
    }
  };
};

const formatPhPayload = payload => {
  return {
    role: payload.role,
    additionalInformations: {
      birthdate: payload.birthdate,
      english_level: payload.english_level,
      arabic_level: payload.arabic_level,
      details: payload.details,
      main_location: payload.main_location,
      is_pro: payload.is_pro,
      is_internal: payload.is_internal
    },
    status: payload.status,
    services: payload.services,
    user: {
      name: payload.name,
      phone: payload.phone
    }
  };
};

export const UserApi = {
  /**
   * Create business user account.
   * We need to first remove the info that are instead related to photographer acount otherwise api call will
   * return 400
   */
  createBusiness(payload, guid, isAdmin, subClientRole = null) {
    const clonedPayload = JSON.parse(JSON.stringify(payload));
    delete clonedPayload.additionalInformations.birthdate;
    delete clonedPayload.additionalInformations.english_level;
    delete clonedPayload.additionalInformations.arabic_level;
    delete clonedPayload.additionalInformations.arabic_level;
    delete clonedPayload.additionalInformations.details;
    delete clonedPayload.additionalInformations.size;
    delete clonedPayload.confirmPassword;
    delete clonedPayload.bank;

    clonedPayload.additionalInformations.country = clonedPayload.additionalInformations.country.value;
    clonedPayload.additionalInformations.contact_name = `${clonedPayload.additionalInformations.contact_name} ${clonedPayload.additionalInformations.contact_surname}`;

    delete clonedPayload.additionalInformations.contact_surname;
    // Temporarily setting name and phone even if not needed. To be removed
    clonedPayload.user.name = `${clonedPayload.user.name} ${clonedPayload.user.surname}`;
    delete clonedPayload.user.surname;
    clonedPayload.user.phone = clonedPayload.additionalInformations.contact_phone;

    if (isAdmin) {
      return instance.post(`/signup/admin/client${guid ? '?parent=' + guid : ''}`, clonedPayload);
    } else {
      return instance.post(
        `/${RESOURCE_SIGNUP}/client${guid ? `?parent=${encodeURIComponent(guid)}` : ''}${subClientRole ? `&subClientRole=${encodeURIComponent(subClientRole)}` : ''}`,
        clonedPayload
      );
    }
  },
  /**
   * Create photographer user account.
   * We need to first remove the info that are instead related to business acount otherwise api call will
   * return 400
   */
  createPhotographer(payload) {
    delete payload.additionalInformations.company_name;
    delete payload.additionalInformations.industry;
    delete payload.additionalInformations.size;
    delete payload.additionalInformations.country;
    delete payload.additionalInformations.contact_email;
    delete payload.additionalInformations.contact_phone;
    delete payload.additionalInformations.contact_name;
    delete payload.additionalInformations.contact_surname;
    delete payload.confirmPassword;

    payload.additionalInformations.details.bank = payload.bank;

    /*
     * format user name and surname data for BE
     */
    payload.user.name = `${payload.user.name} ${payload.user.surname}`;
    delete payload.user.surname;
    delete payload.bank;

    return instance.post(`/${RESOURCE_SIGNUP}/photographer`, payload);
  },
  /**
   * Api call to retrieve a single user detail
   */
  getUser({ id, role }) {
    return instance.post(`/${RES_USER}/${id}`, {
      requestedUserRole: role
    });
  },
  /**
   * Api call to retrieve users based on filters
   */
  searchUsers({ payload }) {
    return instance.post(`${RES_USERS}/${RES_ACTION}/search`, payload);
  },
  /**
   * Update user endpoint
   */
  updateUser({ id, payload }) {
    const clonedPayload = JSON.parse(JSON.stringify(payload));

    const formattedPayload = {
      client: () => formatClientPayload(clonedPayload),
      photographer: () => formatPhPayload(clonedPayload)
    };

    const completePayload = formattedPayload[payload.role]();

    return instance.post(`${RES_USER}/update/${id}`, completePayload);
  },
  /**
   * Endpoint used to check if the user with the given address exists
   * @param {String} payload - email address
   * @returns {Boolean} true if email exists, otherwise false
   */
  checkEmailExistence(payload) {
    return instance.get(`/${RES_USER}/${RES_ACTION}/email-checker/${payload}`);
  },
  /**
   * Endpoint used to retrieve business by its business guid
   * @param {String} payload - business guid
   * @returns {Object} business - formatted business user
   */
  getBusinessByGuid(payload) {
    return instance.get(`/${RES_USER}/${RES_BUSINESS}/${payload}`);
  },

  /**
   * Send OTP to the user's phone number.
   *
   * @param {String} phoneNumber - The phone number to send the OTP to.
   * @returns {Promise} - The response from the API call.
   */
  sendOtp(phoneNumber) {
    return instance.post('/otp', { phone_number: phoneNumber });
  },
  /**
   * Send OTP to the user's phone number.
   *
   * @param {String} phoneNumber - The phone number to send the OTP to.
   * @returns {Promise} - The response from the API call.
   */
  verifyOtp(phoneNumber, otp) {
    return instance.post('/otp/verify', { phone_number: phoneNumber, otp: otp });
  },
  /**
   * Send abandonment data to the backend.
   *
   * @param {Object} payload - Data of the user who abandoned the registration process.
   * @returns {Promise} - The response from the API call.
   */
  abandon(payload) {
    return instance.post('/user/action/abandon', payload);
  },

  deleteSubClient(id) {
    return instance.delete(`${RES_USER}/delete/${id}`);
  },
  updateSubClientRole({ id, payload }) {
    return instance.post(`/user/update-sub-role/${id}`, payload);
  },
  /**
   * Fetch unique user locations (Admin only)
   * @returns {Promise<Array>} - A promise that resolves to an array of unique locations
   */
  getUniqueLocations(targetRole) {
    return instance
      .get(`/${RES_USER}/${RES_ACTION}/get-unique-locations/${targetRole}`)
      .then(response => {
        // Check if the response has the expected structure
        if (response.data && Array.isArray(response.data.locations)) {
          return response.data.locations;
        } else {
          console.error('Unexpected response structure:', response.data);
          return []; // Return an empty array if the structure is not as expected
        }
      })
      .catch(error => {
        console.error('Error fetching unique locations:', error);
        throw error;
      });
  },
  /**
   * Fetch unique user locations (Admin only)
   * @returns {Promise<Array>} - A promise that resolves to an array of unique locations
   */
  getRevenueRange() {
    return instance
      .get(`/${RES_USER}/${RES_ACTION}/get-revenue-range`)
      .then(response => {
        // Check if the response has the expected structure
        if (response.data) {
          return response.data.range;
        } else {
          console.error('Unexpected response structure:', response.data);
          return {}; // Return an empty array if the structure is not as expected
        }
      })
      .catch(error => {
        console.error('Error fetching revenue range:', error);
        throw error;
      });
  }
};

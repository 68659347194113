import instance, { getUri } from '../index';

const RES_REPORTS = 'reports';

export const ReportApi = {
  /**
   * Returning a downloadable csv file
   * @param {Object} payload
   * @returns
   */
  downloadReport(payload) {
    const { from, to, type } = payload;
    const uri = getUri({
      url: `${RES_REPORTS}/csv`,
      params: `from=${from}&to=${to}&type=${type}`
    });
    window.open(uri, 'Download');
  },
  /**
   * Returning shoots reports
   */
  shootsReport(payload) {
    return instance.post(`${RES_REPORTS}/shoots`, payload);
  },
  /**
   * Returning user repors
   */
  usersReport(payload) {
    return instance.post(`${RES_REPORTS}/users`, payload);
  }
};

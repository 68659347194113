<template>
  <BaseNavbar class="navbar">
    <template #center>
      <section :class="['navbar__navigations', { 'navbar__navigations-responsive': !isAdmin }]">
        <BaseButton v-if="!isEditor" icon="home" :icon_style="icon_style" :label="$t('HOME')" @click.native="handleRoute('home')" :class="{ 'navbar__navigations--active': $route.name === 'home' }" />

        <BaseButton :icon="getIcon" :icon_style="icon_style" :label="getLabel" @click.native="$router.push({ name: 'orders' })" :class="{ 'navbar__navigations--active': $route.name === 'orders' }" />
        <template v-if="isAdmin">
          <BaseButton
            icon="image"
            :icon_style="icon_style"
            :label="$t('PACKAGES')"
            :class="{ 'navbar__navigations--active': $route.name === 'packages' }"
            @click.native="$router.push({ name: 'packages' })"
          />
          <BaseButton
            icon="users"
            :icon_style="icon_style"
            :label="$t('ACCOUNTS')"
            :class="{ 'navbar__navigations--active': $route.name === 'accounts' }"
            @click.native="$router.push({ name: 'accounts' }, () => {})"
          />
          <BaseButton
            icon="reports"
            :icon_style="icon_style"
            :label="$t('REPORTS')"
            :class="{ 'navbar__navigations--active': $route.name === 'report' }"
            @click.native="$router.push({ name: 'report' })"
          />
        </template>
        <template v-if="isClient && !isB2C && !isSubClient">
          <BaseButton
            icon="users"
            :icon_style="icon_style"
            :label="$t('ACCOUNTS')"
            :class="{ 'navbar__navigations--active': $route.name === 'subAccounts' }"
            @click.native="$router.push({ name: 'subAccounts' })"
          />
        </template>
      </section>
    </template>
    <template #right>
      <div :class="['navbar__actions', { 'navbar__actions-responsive': !isAdmin }]">
        <BookNewShootButton v-if="isAdmin || isClient" />
        <RegisterBusinessButton v-if="isAdmin" />
        <UserButton />
      </div>
      <div v-if="isAdmin" class="navbar__collapse">
        <BaseIcon icon="hamburger" @click.native="show_menu = true" />
        <section v-if="show_menu" class="navbar__menu">
          <BaseIcon class="navbar__close-collapse" icon="close" @click.native="show_menu = false" />
          <div class="navbar__menu-navigations">
            <BaseButton icon="home" :icon_style="icon_style" :label="$t('HOME')" @click.native="handleRoute('home')" />
            <BaseButton icon="shuffle" :icon_style="icon_style" :label="$t('ORDERS')" @click.native="handleRoute('orders')" />
            <template v-if="isAdmin">
              <BaseButton icon="image" :icon_style="icon_style" :label="$t('PACKAGES')" @click.native="handleRoute('packages')" />
              <BaseButton icon="users" :icon_style="icon_style" :label="$t('ACCOUNTS')" @click.native="handleRoute('accounts')" />
              <BaseButton icon="reports" :icon_style="icon_style" :label="$t('REPORTS')" @click.native="handleRoute('report')" />
            </template>
          </div>
          <BookNewShootButton v-if="isAdmin || isClient" @click.native="handleShowMenu" />
          <hr />
          <p v-if="isAdmin" @click="handleRoute('notificationsSettings')">{{ $t('MANAGE_NOTIFICATION') }}</p>
          <p @click="handleLogout()">{{ $t('LOGOUT') }}</p>
        </section>
      </div>
    </template>
  </BaseNavbar>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex';

// Common components
import BookNewShootButton from '@common/BookNewShootButton.vue';

// Components
import BaseButton from '@base/BaseButton.vue';
import BaseIcon from '@base/BaseIcon.vue';
import BaseNavbar from '@base/BaseNavbar.vue';
import UserButton from '@components/UserButton.vue';
import RegisterBusinessButton from './RegisterBusinessButton.vue';

// Mixins
import checkBreakpoint from '@utils/mixins/check-breakpoint.js';

export default {
  name: 'navbar',
  mixins: [checkBreakpoint],
  components: {
    BaseButton,
    BaseIcon,
    BaseNavbar,
    BookNewShootButton,
    UserButton,
    RegisterBusinessButton
  },
  data() {
    return {
      active: '',
      icon_style: {
        height: '16px',
        width: '16px'
      },
      show_menu: false,
      tooltip: this.$t('COMING_SOON')
    };
  },
  computed: {
    ...mapGetters({
      isAdmin: 'user/isAdmin',
      isPhotographer: 'user/isPhotographer',
      isClient: 'user/isClient',
      isEditor: 'user/isEditor',
      isSubClient: 'user/isSubClient',
      isB2C: 'user/isB2C'
    }),
    /**
     * use for get the icon of the menu for shoot/orders;
     */
    getIcon() {
      if (this.isAdmin) return 'shuffle';
      return 'image';
    },
    /**
     * use for get the label of the menu for shoot/orders;
     */
    getLabel() {
      if (this.isAdmin) return this.$t('ORDERS');
      return this.$t('SHOOTS');
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleWindowSize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleWindowSize);
  },
  methods: {
    /*
     * use to change the value of the show_menu
     */
    handleShowMenu() {
      this.show_menu = !this.show_menu;
    },
    handleWindowSize() {
      if (this.checkBreakpoint({ lowerBoundary: 880 })) this.show_menu = false;
    },
    /*
     * use to set the route on click
     */
    handleRoute(type) {
      this.$router.push({ name: type });
      this.handleShowMenu();
    }
  }
};
</script>

<style lang="scss">
.navbar {
  color: var(--main-text-color);
  &__actions {
    display: flex;

    > .baseButton:first-child {
      margin-right: 15px;
    }
  }

  &__menu {
    align-items: center;
    background-color: var(--main-bg-color);
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: fixed;
    padding: 70px 68px 31px 68px;
    top: 0;
    width: 100vw;
    z-index: 999;

    > button {
      width: 239px;
    }

    &-navigations {
      display: flex;
      flex-direction: column;

      > button {
        background: var(--secondary-bg-color);
        margin-bottom: 25px;
        width: 239px;
        &.navbar__menu-navigations--active,
        &:hover {
          background: var(--attach-bg-color);
        }
      }
    }

    hr {
      background-color: var(--hr-bg-color);
      height: 1px;
      margin-bottom: 24.5px;
      margin-top: 29.5px;
      max-width: 239px;
      width: 100%;
    }
  }

  &__close-collapse {
    position: absolute;
    right: 27px;
    top: 31px;
  }

  &__collapse {
    display: none;
  }
  &__navigations {
    > button {
      font-weight: bold;
      margin: 0 7px !important;
      &.navbar__navigations--active,
      &:hover {
        background: var(--attach-bg-color);
      }
    }
  }

  &__user {
    margin-left: 15px;
  }
}

@include responsive($max: '942px') {
  .navbar {
    &__actions,
    &__navigations {
      display: none;
    }

    &__actions-responsive,
    &__navigations-responsive {
      display: flex;
    }

    &__collapse {
      display: block;
    }
  }
}

@include responsive($max: laptopXM) {
  .navbar {
    &__actions,
    &__navigations-responsive {
      .baseButton {
        height: 44px;
        width: 44px;

        .q-btn__wrapper {
          padding: 0;
        }

        &__label {
          display: none;
        }
      }
    }
  }
}
</style>

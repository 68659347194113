import instance from '../index';

const RES_ACTION = 'action';
const RES_ORDER = 'order';
const RES_USERS = 'users';

export const OrderApi = {
  checkout({ order_id, payload }) {
    return instance.post(`${RES_ORDER}/${order_id}/${RES_ACTION}/checkout`, payload);
  },
  synchronizeClientPayment(payload) {
    const clonedPayload = JSON.parse(JSON.stringify(payload));
    if (!payload.billing_address.line2) delete clonedPayload.billing_address.line2;

    delete clonedPayload.name;
    delete clonedPayload.surname;

    return instance.post(`${RES_USERS}/${RES_ACTION}/synchronize-client-payment-data`, clonedPayload);
  },
  createStripeSession(payload) {
    return instance.post('/stripe/create-session', payload);
  }
};

import { getCookie } from '@utils/cookies';

const fetchWithConfig = async (url, options = {}) => {
  const jwt = getCookie('jwt');
  const baseURL = `${process.env.VUE_APP_BASE_DOMAIN}/v1`;
  const defaultOptions = {
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json'
    }
  };
  const fullOptions = {
    ...defaultOptions,
    ...options,
    headers: {
      ...defaultOptions.headers,
      ...options.headers
    }
  };
  const response = await fetch(`${baseURL}${url}`, fullOptions);
  if (!response.ok) {
    const error = new Error('HTTP error');
    error.response = response;
    throw error;
  }
  return response;
};

export const QuotesApi = {
  generateQuote(services) {
    return fetchWithConfig('/quote', {
      method: 'POST',
      body: JSON.stringify({ services })
    });
  },

  queryInvoices(query, conversationId) {
    return fetchWithConfig('/query-invoices', {
      method: 'POST',
      body: JSON.stringify({ query, conversationId })
    });
  },

  optimizeQuery(query) {
    return fetchWithConfig('/optimize-query', {
      method: 'POST',
      body: JSON.stringify({ query })
    });
  }
};

<template>
  <q-tooltip content-class="bg-yellow text-dark shadow-4" content-style="font-family: 'Inter', sans-serif; letter-spacing: 1px;" :offset="offset" :anchor="anchor" :self="self">
    {{ tooltip }}
  </q-tooltip>
</template>
<script>
export default {
  name: 'BaseTooltip',
  props: {
    /**
     * Setting the anchor position (starting position of tooltip ralative to its target)
     */
    anchor: { default: 'top middle', type: String },
    /**
     * Setting the tooltip offset [horizontal, vertical]
     */
    offset: { default: () => [0, 25], type: Array },
    /**
     * Tooltip's own position relative to its target
     */
    self: { default: 'top middle', type: String },
    /**
     * Use this prop to set the button tooltip text
     */
    tooltip: { default: '', type: String }
  }
};
</script>

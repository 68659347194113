import Vue from 'vue';

const firstUppercase = Vue.filter('firstUppercase', value => {
  if (!value || typeof value !== 'string') return '';

  const firstLetter = value.charAt(0);
  const fisteUppercaseLetter = firstLetter.toUpperCase();
  const allWithoutFirstLetter = value.slice(1);

  return `${fisteUppercaseLetter}${allWithoutFirstLetter.toLowerCase()}`;
});

const lowercase = Vue.filter('lowercase', value => {
  if (!value || typeof value !== 'string') return '';

  return value.toLowerCase();
});

export default {
  firstUppercase,
  lowercase
};

import i18n from '@/i18n';
import store from '@/store/index';

export const BEING_EDITED = 'BEING_EDITED';
export const ARCHIVED_SHOOT = 'ARCHIVED_SHOOT';
export const CONFIRMED_SHOOT = 'CONFIRMED_SHOOT';
export const UNCONFIRMED_SHOOT = 'UNCONFIRMED_SHOOT';
export const UPCOMING_SHOOT = 'UPCOMING_SHOOT';
export const EDIT_READY = 'EDIT_READY';
export const SHOOT_IN_PROGRESS = 'SHOOT_IN_PROGRESS';
export const DOWNLOAD_READY = 'DOWNLOAD_READY';

// BE STATUSES
export const ASSIGNED = 'assigned';
export const CANCELED = 'canceled';
export const CONFIRMED = 'confirmed';
export const COMPLETED = 'completed';
export const READY = 'ready';
export const REDEEMABLE = 'redeemable';
export const SCHEDULED = 'scheduled';
export const TO_PAY = 'toBePayed';
export const TO_SCHEDULE = 'toSchedule';
export const UPLOADED = 'uploaded';

export const statuses = [
  {
    color: '#FFEDB7',
    label: i18n.t('STATUSES.REDEEM_SHOOTS'),
    value: [REDEEMABLE]
  },
  {
    color: '#FFEDB7',
    label: i18n.t('STATUSES.PENDING'),
    value: [ASSIGNED, SCHEDULED, TO_SCHEDULE, TO_PAY]
  },
  {
    color: '#FECB30',
    label: i18n.t('STATUSES.CONFIRMED'),
    value: [CONFIRMED]
  },
  {
    color: '#BC5090',
    label: i18n.t('STATUSES.EDIT_READY'),
    value: [UPLOADED]
  },
  {
    color: '#58508D',
    label: i18n.t('STATUSES.READY_DOWNLOAD'),
    value: [READY]
  },
  {
    color: '#003F5C',
    label: i18n.t('STATUSES.COMPLETE'),
    value: [COMPLETED]
  },
  {
    color: '#FF6361',
    label: i18n.t('STATUSES.CANCELED'),
    value: [CANCELED]
  }
];

const adminMapping = {
  [UPCOMING_SHOOT]: [ASSIGNED, CONFIRMED, SCHEDULED],
  [SHOOT_IN_PROGRESS]: [UPLOADED],
  [DOWNLOAD_READY]: [READY],
  [ARCHIVED_SHOOT]: [COMPLETED, CANCELED]
};

const clientMapping = {
  [UPCOMING_SHOOT]: [ASSIGNED, CONFIRMED, REDEEMABLE, SCHEDULED],
  [SHOOT_IN_PROGRESS]: [UPLOADED],
  [DOWNLOAD_READY]: [READY],
  [ARCHIVED_SHOOT]: [COMPLETED, CANCELED]
};
const editorMapping = {
  [SHOOT_IN_PROGRESS]: [UPLOADED]
};
const photographerMapping = {
  [UNCONFIRMED_SHOOT]: [ASSIGNED],
  [CONFIRMED_SHOOT]: [CONFIRMED],
  [DOWNLOAD_READY]: [UPLOADED, COMPLETED, READY]
};

const helpers = {
  /**
   * Retrieves backend statuses based on fe status
   */
  retrieveBEStatuses({ isAdmin, isClient, isEditor, isPhotographer, status }) {
    if (isAdmin) return adminMapping[status];
    if (isClient) return clientMapping[status];
    if (isEditor) return editorMapping[status];
    if (isPhotographer) return photographerMapping[status];

    return [];
  },
  /**
   * Return status FE label based on the status BE value
   */
  mapStatus(status) {
    if (status === 'assigned') return status;
    const statusMap = statuses.find(_status => _status.value.includes(status)) || {};
    return statusMap.label;
  },
  /**
   * Return status color based on the status BE value
   */
  getStatusColor(status) {
    const statusMap = statuses.find(_status => _status.value.includes(status)) || {};
    return statusMap.color;
  },
  showWizard(shoot) {
    const shootsStatusValue = shoot.status;

    let mapping = {};

    if (store.getters['user/isAdmin']) return false;
    if (store.getters['user/isClient']) return false;
    if (store.getters['user/isEditor']) return false;
    if (store.getters['user/isPhotographer']) mapping = photographerMapping;

    return mapping[UNCONFIRMED_SHOOT].includes(shootsStatusValue);
  },
  /**
   * Retrieve color based on status
   */
  retrieveStatusColor(status) {
    const statusColor = statuses.find(_status => _status.value.includes(status)) || {};
    return statusColor.color || '';
  },
  /**
   * Retrieve label based on status
   */
  retrieveStatusLabel(status) {
    const statusLabel = statuses.find(_status => _status.value.includes(status)) || {};
    return statusLabel.label || '';
  },

  /**
   * Retrieving status banner message based on role
   */
  retrieveStatusBanner({ isAdmin, isClient, isEditor, isPhotographer, status, name = '', isVideo, isPreviewEnabled = false, isRawsPreviewEnabled = false }) {
    let banners = {};

    let ADMIN_SCHEDULED_BANNER;

    if (name) {
      if (isVideo) ADMIN_SCHEDULED_BANNER = i18n.t('STATUSES.BANNERS.ADMIN_PENDING_VIDEOGRAPHY');
      else ADMIN_SCHEDULED_BANNER = i18n.t('STATUSES.BANNERS.ADMIN_PENDING');
    } else {
      if (isVideo) ADMIN_SCHEDULED_BANNER = i18n.t('STATUSES.BANNERS.ADMIN_PENDING_NO_VIDEOGRAPHER');
      else ADMIN_SCHEDULED_BANNER = i18n.t('STATUSES.BANNERS.ADMIN_PENDING_NO_PH');
    }
    let ADMIN_ASSIGNED_BANNER;

    if (name) {
      if (isVideo) ADMIN_ASSIGNED_BANNER = i18n.t('STATUSES.BANNERS.ADMIN_ASSIGNED_VIDEOGRAPHER', { photographer: name });
      else ADMIN_ASSIGNED_BANNER = i18n.t('STATUSES.BANNERS.ADMIN_ASSIGNED', { photographer: name });
    }

    let TO_SCHEDULE_BANNER;
    if (isVideo) TO_SCHEDULE_BANNER = i18n.t('STATUSES.BANNERS.TO_SCHEDULE_VIDEOGRAPHY');
    else TO_SCHEDULE_BANNER = i18n.t('STATUSES.BANNERS.TO_SCHEDULE');

    if (isAdmin) {
      banners[ASSIGNED] = ADMIN_ASSIGNED_BANNER; // done
      banners[SCHEDULED] = ADMIN_SCHEDULED_BANNER; // done
      banners[CONFIRMED] = i18n.t('STATUSES.BANNERS.ADMIN_CONFIRMED', { photographer: name }); // done
      banners[UPLOADED] = isRawsPreviewEnabled ? i18n.t('STATUSES.BANNERS.ADMIN_WAITING_RAWS_PREVIEW') : i18n.t('STATUSES.BANNERS.ADMIN_READY_EDITING'); // done

      banners[READY] = isPreviewEnabled ? i18n.t('STATUSES.BANNERS.ADMIN_WAITING_PREVIEW') : i18n.t('STATUSES.BANNERS.ADMIN_READY_DOWNLOAD'); // done
      banners[COMPLETED] = i18n.t('STATUSES.BANNERS.ADMIN_COMPLETE'); // done
      banners[CANCELED] = i18n.t('STATUSES.BANNERS.ADMIN_CANCELED'); // done
      banners[TO_SCHEDULE] = TO_SCHEDULE_BANNER; // done
    }

    let CLIENT_ASSIGNED_BANNER;
    if (isVideo) CLIENT_ASSIGNED_BANNER = i18n.t('STATUSES.BANNERS.CLIENT_VI_ASSIGNED');
    else CLIENT_ASSIGNED_BANNER = i18n.t('STATUSES.BANNERS.CLIENT_PH_ASSIGNED');

    let CLIENT_SCHEDULED_BANNER;
    if (isVideo) CLIENT_SCHEDULED_BANNER = i18n.t('STATUSES.BANNERS.CLIENT_SCHEDULED_VIDEOGRAPHY');
    else CLIENT_SCHEDULED_BANNER = i18n.t('STATUSES.BANNERS.CLIENT_SCHEDULED');

    if (isClient) {
      banners[ASSIGNED] = CLIENT_ASSIGNED_BANNER; // done
      banners[CONFIRMED] = i18n.t('STATUSES.BANNERS.CLIENT_BUSINESS_CONFIRMED'); // done
      banners[READY] = isPreviewEnabled ? i18n.t('STATUSES.BANNERS.CLIENT_BUSINESS_PREVIEW') : i18n.t('STATUSES.BANNERS.CLIENT_READY_DOWNLOAD'); // done

      banners[UPLOADED] = isRawsPreviewEnabled ? i18n.t('STATUSES.BANNERS.CLIENT_BUSINESS_RAWS_PREVIEW') : i18n.t('STATUSES.BANNERS.CLIENT_BUSINESS_UPLOADED'); // done
      banners[SCHEDULED] = CLIENT_SCHEDULED_BANNER; //done
      banners[COMPLETED] = i18n.t('STATUSES.BANNERS.CLIENT_BUSINESS_COMPLETE'); // done
      banners[TO_SCHEDULE] = TO_SCHEDULE_BANNER; // done
    }
    if (isEditor) {
      banners[UPLOADED] = i18n.t('STATUSES.BANNERS.ADMIN_READY_EDITING'); // done
    }

    if (isPhotographer) {
      banners[ASSIGNED] = i18n.t('STATUSES.BANNERS.PH_ASSIGNED'); // done
      banners[CONFIRMED] = i18n.t('STATUSES.BANNERS.PH_CONFIRMED'); // done
      banners[READY] = i18n.t('STATUSES.BANNERS.PH_READY_EDITING'); // done
      banners[COMPLETED] = i18n.t('STATUSES.BANNERS.PH_COMPLETED'); // done
    }

    return banners[status];
  }
};

export default helpers;

const defaultState = () => {
  return {
    settings: {}
  };
};

const settings = {
  namespaced: true,
  state: defaultState(),
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState());
    },
    /**
     * Update status settings
     */
    updateSettings(state, settings) {
      state.settings = settings;
    }
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    /**
     * Add new error to state errors array
     */
    updateSettings({ commit }, settings) {
      commit('updateSettings', settings);
    }
  },
  getters: {
    /**
     * Retrieving settings
     */
    getSettings: state => state.settings
  }
};

export default settings;

export default [
  {
    path: '/stripe-success',
    name: 'stripeSuccess',
    component: () => import(/* webpackChunkName: "home" */ '@views/StripeSuccess.vue')
  },
  {
    path: '/stripe-error',
    name: 'stripeError',
    component: () => import(/* webpackChunkName: "home" */ '@views/StripeError.vue')
  }
];

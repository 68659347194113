import instance from '../index';

const RES_SERVICE = 'service';
const RES_SERVICES = 'services';

export const ServiceApi = {
  create({ purpose = 1, name }) {
    return instance.post(`${RES_SERVICE}`, {
      name,
      purpose_id: purpose
    });
  },
  delete(id) {
    return instance.delete(`${RES_SERVICE}/delete/${id}`);
  },
  reassign({ from_id, to_id }) {
    return instance.post(`${RES_SERVICE}/actions/reassign`, {
      from_id: `${from_id}`,
      to_id: `${to_id}`
    });
  },
  search(payload) {
    return instance.post(`${RES_SERVICES}/search`, payload);
  },
  update({ id, purpose = 1, name }) {
    return instance.post(`${RES_SERVICE}/update/${id}`, {
      name,
      purpose_id: purpose
    });
  }
};

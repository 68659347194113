<template>
  <BaseButton icon="plus" :icon_style="iconStyle" :custom_style="buttonStyle" :label="$t('BOOK_SHOOT')" @click.native="$router.push({ name: redirectRoute })" />
</template>

<script>
// Vuex
import { mapGetters } from 'vuex';

// Base Components
import BaseButton from '@base/BaseButton.vue';

// Utils
import helpers from '@utils/helpers';

export default {
  name: 'BookNewShootsButton',
  components: {
    BaseButton
  },
  data() {
    return {
      buttonStyle: {
        'background-color': helpers.getCssVariable('--main-btn-color'),
        'box-shadow': `0 3px 14px ${helpers.getCssVariable('--main-btn-color-shadow')}`,
        color: helpers.getCssVariable('--main-text-color')
      },
      iconStyle: {
        fill: helpers.getCssVariable('--main-text-color'),
        stroke: helpers.getCssVariable('--main-text-color')
      }
    };
  },
  computed: {
    ...mapGetters({
      isSubClient: 'user/isSubClient',
      isB2C: 'user/isB2C'
    }),
    redirectRoute() {
      if (this.isB2C) return 'flashyShoots';
      return !this.isSubClient ? 'bookShoot' : 'redeemShoot';
    }
  }
};
</script>

<template>
  <nav class="baseNavbar">
    <div class="baseNavbar__image" @click="handleRoute('home')">
      <img class="baseNavbar__logo" src="@/assets/logo.png" :title="`version: ${version}`" />
      <img class="baseNavbar__simpleLogo" src="@/assets/svg/logo.svg" />
    </div>
    <div class="baseNavbar__center">
      <slot name="center" />
    </div>
    <div class="baseNavbar__right">
      <slot name="right" />
    </div>
  </nav>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex';

export default {
  name: 'BaseNavbar',
  data() {
    return {
      version: process.env.VUE_APP_BUILD_NUMBER || 'local'
    };
  },
  computed: {
    ...mapGetters({
      isClient: 'user/isClient',
      isEditor: 'user/isEditor'
    })
  },
  methods: {
    /*
     * use to set the route on click
     */
    handleRoute(type) {
      if (!this.isEditor) this.$router.push({ name: type });
    }
  }
};
</script>

<style lang="scss" scoped>
.baseNavbar {
  align-items: center;
  background-color: var(--navbar-bg-color);
  display: flex;
  height: $header-height;
  padding: 16px 30px;
  position: relative;

  &__logo {
    cursor: pointer;
    height: 40px;
    width: 140px;

    @include responsive($max: '942px') {
      display: none;
    }
  }

  &__simpleLogo {
    display: none;

    @include responsive($max: '942px') {
      display: block;
    }
  }

  &__right {
    display: flex;
    margin-left: auto;
  }
}
</style>

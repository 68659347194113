import instance from '../index';

const RES_ACTION = 'action';

export const MediaApi = {
  /**
   * Given a zip with photos, a type (servie or package) and the id it uploads the photos into
   * an aws bucket
   */
  uploadPictures({ id, type, blob, onUploadProgress }) {
    return instance.post(`${RES_ACTION}/upload-pictures?id=${id}&type=${type}`, blob, {
      headers: {
        'Content-Type': 'application/octet-stream'
      },
      onUploadProgress
    });
  },
  /**
   * Upload package photos
   */
  uploadPackagePictures({ id, blob, onUploadProgress }) {
    return this.uploadPictures({ id, type: 'package', blob, onUploadProgress });
  },
  /**
   * Upload service photos
   */
  uploadServicePictures({ id, blob }) {
    return this.uploadPictures({ id, type: 'service', blob });
  }
};
